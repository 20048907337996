<template>
  <div>
    <div class="page-title">
      <h3>Редактирование объекта</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/objects')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <form class="form" @submit.prevent="submitHandler">
      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="id" type="text" v-model="id" disabled>
            <label for="id">ID</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <select id="service_engineer" ref="service_engineer_select" v-model="service_engineer">
              <option value="" selected>Нет</option>
              <option v-for="r in service_engineer_list.users" :key="r.id" :value="r.id">{{r.last_name}}
                {{r.first_name}}
              </option>
            </select>
            <label for="service_engineer">Инженер сервиса</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="external_id" type="text" v-model="external_id">
            <label for="external_id">Внешний ID</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="service_contract" type="text" v-model="service_contract">
            <label for="service_contract">Договор сервиса</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <select id="customer_id" ref="customer_select" v-model="customer_id"
                    :class="{invalid: $v.customer_id.$error}">
              <option value="" disabled selected>Выбрать</option>
              <option v-for="r in customer_list" :key="r.id" :value="r.id">{{r.name}}</option>
            </select>
            <label for="customer_id">Контрагент</label>
            <small class="helper-text invalid" v-if="$v.customer_id.$error">Не выбран
              контрагент</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="service_warranty" type="text" v-model="service_warranty">
            <label for="service_warranty">Гарантия сервиса</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <select id="cluster_id" ref="cluster_select" v-model="cluster_id">
              <option v-for="r in cluster_list" :key="r.id" :value="r.id">{{r.name}}</option>
            </select>
            <label for="cluster_id">Кластер</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="service_start_date" type="text" v-model.lazy="service_start_date" class="datepicker" v-datePicker="service_start_date">
            <label for="service_start_date">Начало сервиса</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="locality" type="text" v-model="locality"
                   :class="{invalid: $v.locality.$error}">
            <label for="locality">Населенный пункт</label>
            <small class="helper-text invalid" v-if="$v.locality.$error">Не указан населенный
              пункт</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <select id="maintenance_engineer" ref="maintenance_engineer_select"
                    v-model="maintenance_engineer">
              <option value="" selected>Нет</option>
              <option v-for="r in maintenance_engineer_list.users" :key="r.id" :value="r.id">
                {{r.last_name}} {{r.first_name}}
              </option>
            </select>
            <label for="maintenance_engineer">Инженер эксплуатации</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="address" type="text" v-model="address" :class="{invalid: $v.address.$error}">
            <label for="address">Адрес</label>
            <small class="helper-text invalid" v-if="$v.address.$error">Не указан адрес</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="maintenance_contract" type="text" v-model="maintenance_contract">
            <label for="maintenance_contract">Договор экслуатации</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="original_address" type="text" v-model="original_address">
            <label for="original_address">Оригинальный адрес</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="maintenance_warranty" type="text" v-model="maintenance_warranty">
            <label for="maintenance_warranty">Гарантия экслуатации</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="location_lat" type="text" v-model="location_lat">
            <label for="location_lat">Местоположение (широта)</label>
            <small class="helper-text invalid" v-if="$v.location_lat.$error">Не указана широта</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="opening_date" type="text" v-model.lazy="opening_date" class="datepicker" v-datePicker="opening_date">
            <label for="opening_date">Дата открытия</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="location_lng" type="text" v-model="location_lng">
            <label for="location_lng">Местоположение (долгота)</label>
            <small class="helper-text invalid" v-if="$v.location_lng.$error">Не указана долгота</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="reconstruction_date" type="text" v-model.lazy="reconstruction_date" class="datepicker" v-datePicker="reconstruction_date">
            <label for="reconstruction_date">Дата реконструкции</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="supervisor" type="text" v-model="supervisor">
            <label for="supervisor">Руководитель</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="close_date" type="text" v-model.lazy="close_date" class="datepicker" v-datePicker="close_date">
            <label for="close_date">Дата закрытия</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="phone" type="text" v-model="phone" :class="{invalid: $v.phone.$error}">
            <label for="phone">Телефон</label>
            <small class="helper-text invalid" v-if="$v.phone.$error">Номер должен состоять только из цифр</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="store_format" type="text" v-model="store_format">
            <label for="store_format">Формат магазина</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="created_at" type="text" v-model="created_at" disabled>
            <label for="created_at">Время создания</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="updated_at" type="text" v-model="updated_at" disabled>
            <label for="updated_at">Время изменения</label>
          </div>
        </div>
      </div>

      <button class="btn waves-effect waves-light blue darken-4" type="submit">
        Сохранить
        <i class="material-icons right">send</i>
      </button>
    </form>
  </div>
</template>

<script>
  import {required, numeric, decimal} from 'vuelidate/lib/validators'
  import dateFilter from '../../filters/date.filter'
  import convertDateString from '../../utils/date'

  export default {
    metaInfo() {
      return {
        title: 'Редактирование объекта'
      }
    },
    data: () => ({
      loading: true,
      customer_select: null,
      cluster_select: null,
      service_engineer_select: null,
      maintenance_engineer_select: null,
      service_engineer_list: [],
      maintenance_engineer_list: [],
      customer_list: [],
      cluster_list: [],
      id: null,
      external_id: '',
      customer_id: null,
      cluster_id: null,
      locality: '',
      address: '',
      original_address: '',
      location_lat: null,
      location_lng: null,
      supervisor: '',
      phone: null,
      service_engineer: null,
      service_contract: '',
      service_warranty: 0,
      service_start_date: null,
      maintenance_engineer: null,
      maintenance_contract: '',
      maintenance_warranty: 0,
      opening_date: null,
      reconstruction_date: null,
      close_date: null,
      store_format: '',
      created_at: null,
      updated_at: null,
    }),
    validations: {
      customer_id: {required, numeric},
      locality: {required},
      address: {required},
      phone: {numeric},
      location_lat: {required, decimal},
      location_lng: {required, decimal},
    },
    async mounted() {
      const object = await this.$store.dispatch('fetchObjectById', this.$route.params.id)

      this.id = object.id
      this.external_id = object.external_id || ''
      this.customer_id = object.customer ? object.customer.id : null
      this.cluster_id = object.cluster ? object.cluster.id : null
      this.locality = object.locality || ''
      this.address = object.address || ''
      this.original_address = object.original_address || ''
      this.location_lat = object.location ? object.location.lat : null
      this.location_lng = object.location ? object.location.lng : null
      this.supervisor = object.supervisor || ''
      this.phone = object.phone || null
      this.service_contract = object.service_contract || ''
      this.service_warranty = object.service_warranty || 0
      this.service_engineer = object.service_engineer ? object.service_engineer.id : ""
      this.maintenance_engineer = object.maintenance_engineer ? object.maintenance_engineer.id : ""
      this.maintenance_contract = object.maintenance_contract || ''
      this.maintenance_warranty = object.maintenance_warranty || 0
      this.store_format = object.store_format || ''
      this.service_start_date = object.service_start_date ? dateFilter(object.service_start_date.seconds * 1000, 'date') : null
      this.opening_date = object.opening_date ? dateFilter(object.opening_date.seconds * 1000, 'date') : null
      this.reconstruction_date = object.reconstruction_date ? dateFilter(object.reconstruction_date.seconds * 1000, 'date') : null
      this.close_date = object.close_date ? dateFilter(object.close_date.seconds * 1000, 'date') : null
      this.created_at = dateFilter(object.created_at.seconds * 1000, 'datetime')
      this.updated_at = dateFilter(object.updated_at.seconds * 1000, 'datetime')

      this.customer_list = await this.$store.dispatch('fetchCustomers')
      this.cluster_list = await this.$store.dispatch('fetchObjectClusters')
      this.service_engineer_list = await this.$store.dispatch('fetchUsers', {
        departmentId: 3,
        teamId: null,
        role: 'dispatcher'
      })
      this.maintenance_engineer_list = await this.$store.dispatch('fetchUsers', {
        departmentId: 2,
        teamId: null,
        role: 'dispatcher'
      })

      this.loading = false

      setTimeout(() => {
        this.customer_select = M.FormSelect.init(this.$refs.customer_select)
        this.cluster_select = M.FormSelect.init(this.$refs.cluster_select)
        this.service_engineer_select = M.FormSelect.init(this.$refs.service_engineer_select)
        this.maintenance_engineer_select = M.FormSelect.init(this.$refs.maintenance_engineer_select)
        M.updateTextFields()
      })
    },
    methods: {
      async submitHandler() {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return
        }

        const object = {
          id: this.id,
          external_id: this.external_id,
          customer: {"id": this.customer_id},
          cluster: this.cluster_id ? {"id": this.cluster_id} : null,
          locality: this.locality,
          address: this.address,
          original_address: this.original_address,
          location: {"lat": parseFloat(this.location_lat), "lng": parseFloat(this.location_lng)},
          supervisor: this.supervisor,
          phone: this.phone ? parseInt(this.phone) : null,
          service_engineer: this.service_engineer ? {"id": this.service_engineer} : null,
          maintenance_engineer: this.maintenance_engineer ? {"id": this.maintenance_engineer} : null,
          service_contract: this.service_contract,
          service_warranty: parseInt(this.service_warranty),
          service_start_date: this.service_start_date ? {seconds: convertDateString(this.service_start_date).getTime() / 1000} : null,
          maintenance_contract: this.maintenance_contract,
          maintenance_warranty: parseInt(this.maintenance_warranty),
          opening_date: this.opening_date ? {seconds: convertDateString(this.opening_date).getTime() / 1000} : null,
          reconstruction_date: this.reconstruction_date ? {seconds: convertDateString(this.reconstruction_date).getTime() / 1000} : null,
          close_date: this.close_date ? {seconds: convertDateString(this.close_date).getTime() / 1000} : null,
          store_format: this.store_format,
        }

        await this.$store.dispatch('updateObject', object)
        await this.$router.push('/objects')
      },
    },
    destroyed() {
      if (this.customer_select && this.customer_select.destroy) {
        this.customer_select.destroy()
      }
      if (this.cluster_select && this.cluster_select.destroy) {
        this.cluster_select.destroy()
      }
      if (this.service_engineer_select && this.service_engineer_select.destroy) {
        this.service_engineer_select.destroy()
      }
      if (this.maintenance_engineer_select && this.maintenance_engineer_select.destroy) {
        this.maintenance_engineer_select.destroy()
      }
    }
  }
</script>

<style scoped>
  .switch {
    margin-top: 1rem;
  }
</style>
